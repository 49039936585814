import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "../pages/Home";
import { ImportUsers } from "../pages/ImportData";
import { Login } from "../pages/Login";
import { Payments } from "../pages/Cashflow";
import { SignUp } from "../pages/SignUp";
import { Settings } from "../pages/Settings";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import { GetPaid, Pay } from "../pages/Payments";
import { Error404 } from "../pages/Error404";
import { VerifyEmail } from "../pages/SignUp";
import WhatWeDo from "../pages/SignUp/WhatWeDo";
import TellUsWhoYouAre from "../pages/SignUp/TellUsWhoYouAre";
import TellUsAboutYourself from "../pages/SignUp/TellUsAboutYourself";
import { Companies } from "../pages/Companies";
import { Contacts } from "../pages/Contacts";
import OnboardingRoutes from "./OnboardingRoutes";
import TellUsAboutYourBusiness from "../pages/SignUp/TellUsAboutYourBusiness";
import WhereIsItLocated from "../pages/SignUp/WhereIsItLocated";
import EstimatedMonthlyExpense from "../pages/SignUp/EstimatedMonthlyExpense";
import YouAreAllSetUp from "../pages/SignUp/YouAreAllSetUp";
import Cashflow from "../pages/Cashflow/Cashflow";
import Hire from "../pages/Hire/Hire";
import { GetStarted } from "../pages/GetStarted";
import ZohoOrganizations from "../pages/Companies/ZohoOrganizations";
import { ForgotPassword, ResetPassword } from "../pages/ForgotPassword";
import {
  ChangePassword,
  ChangePasswordForSession,
} from "../pages/ChangePassword";
import { ExperimentSteps } from "../pages/Contacts/components/ExperimentSteps";
import SyncAccountingSoftwarePage from "../pages/SyncAccountingSoftware/SyncAccountingSoftwarePage";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import PricingPage from "../common/PricingPage";
import Banks from "../pages/Banks";
import BanksProvider from "../pages/Banks/context/BanksContext";
import CompaniesContext from "../pages/Companies/contexts/CompaniesContext";
import CompanyDashboard from "../pages/Companies/Dashboard/CompanyDashboard";
import AcceptInvite from "../pages/Settings/components/TeamSettings/AcceptInvite";
import SignUpInviteAccept from "../pages/Settings/components/TeamSettings/SignUpInviteAccept";
import XeroSignUp from "../pages/XeroAuthTest/XeroSignUp";
import XeroSignUpRedirect from "../pages/XeroSignUpRedirect/XeroSignUpRedirect";
import XeroAccountSelection from "../pages/XeroSignUpRedirect/XeroAccountSelection";
import PricingUpgrade from "../pages/Settings/components/PricingPlan/PricingUpgrade";
import SubSuccess from "../pages/Settings/components/PricingPlan/SubSuccess";

const MainRoutes = () => (
  <Routes>
    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<Navigate to={"/get-started"} />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/welcome" element={<Home />} />
      <Route path="/payments" element={<Payments />} />
      <Route path="/exp" element={<ExperimentSteps />} />
      <Route path="/pay" element={<Pay />} />
      <Route
        path="/companies"
        element={
          <CompaniesContext>
            <div
              className="hero-body-companies hero-body"
              style={{
                marginTop: "-0.5rem",
                marginRight: "2.5rem",
                overflowY: "hidden",
              }}
            >
              <CompanyDashboard />
            </div>
          </CompaniesContext>
        }
      />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/get-paid" element={<GetPaid />} />
      <Route path="/cashflow" element={<Cashflow />} />
      <Route path="/your-cfo" element={<Hire />} />
      <Route path="/get-started" element={<GetStarted />} />
      <Route path="/zoho-organizations" element={<ZohoOrganizations />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/pricing-upgrade" element={<PricingUpgrade />} />
      <Route path="/success" element={<SubSuccess />}/>
      <Route path="/banks" element={<Banks />} />

      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/change-password-session/:jwt"
        element={<ChangePasswordForSession />}
      />
      <Route
        path="/sync-accounting-software"
        element={<SyncAccountingSoftwarePage />}
      />
      <Route
        path="/accept_invitation/:randomNumber/:role/:organizationId/:forUser"
        element={<AcceptInvite />}
      />
    </Route>

    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="/" element={<PublicRoutes />}>
      <Route path="login" element={<Login />} />
      <Route path="sign-in-with-xero" element={<XeroSignUp />} />
      <Route
        path="xero-sign-up-redirect/:userId/:isLogin/:userExists"
        element={<XeroSignUpRedirect />}
      />
      <Route path="signup" element={<SignUp />} />
      <Route
        path="/sign_up/:randomNumber/:role/:organizationId/:email"
        element={<SignUpInviteAccept />}
      />
      <Route path="import-data" element={<ImportUsers />} />
      <Route path="forget-password" element={<ForgotPassword />} />
      <Route path="change-password/:jwt" element={<ChangePassword />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Route>

    <Route path="/" element={<OnboardingRoutes />}>
      <Route path="verify-email" element={<VerifyEmail />} />
      <Route path="what-we-do" element={<WhatWeDo />} />
      <Route path="tell-us-who-you-are" element={<TellUsWhoYouAre />} />
      <Route path="account-type-selection" element={<XeroAccountSelection />} />
      <Route path="tell-us-about-yourself" element={<TellUsAboutYourself />} />
      <Route
        path="tell-us-about-your-business"
        element={<TellUsAboutYourBusiness />}
      />
      <Route path="where-is-it-located" element={<WhereIsItLocated />} />
      <Route
        path="estimated-monthly-expense"
        element={<EstimatedMonthlyExpense />}
      />
      <Route path="you-are-all-set-up" element={<YouAreAllSetUp />} />
      <Route
        path="/change-password-session/:jwt"
        element={<ChangePasswordForSession />}
      />
    </Route>

    {/** Error404 route */}
    <Route path="*" element={<Error404 />} />
  </Routes>
);

export default MainRoutes;
