import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { configs } from "../constants/configs";
import { useCashflow } from "../hooks/useCashflow";
import { useRecords } from "../pages/Payments/hooks/useRecords";
import { LoadingOverlay } from "@mantine/core";
import { COLORS } from "../constants/theme";
import { io } from "socket.io-client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFinancialProjection } from "../hooks/useFinancialProjection";
import { ICashflowScenario, ISyncingLogs } from "../constants/types";
import Loader from "../common/Loader";
import { getSyncingInfo } from "../repositories/subscription";
import { doPlanAllowMoreSyncs } from "../modules/doPlanAllow";
import _ from "lodash";
import { PLANS } from "../constants/globalConstants";
import { doesDemoDataExists } from "../repositories/demoData";

const STATUS = {
  contacts: false,
  invoices: false,
  bills: false,
  openingBalance: false,
  timeSpent: 0,
};

const sync_url =
  process.env.REACT_APP_SOCKET_URL_SYNC || "http://localhost:5000";
const sync_server = io(sync_url, {
  path: process.env.REACT_APP_SOCKET_URL_SYNC ? "/sync/socket.io" : undefined,
});

const core_url =
  process.env.REACT_APP_SOCKET_URL_SYNC || "http://localhost:4000";
const core_server = io(core_url, {
  path: process.env.REACT_APP_SOCKET_URL_SYNC ? "/api/socket.io" : undefined,
});

type PlanType = (typeof PLANS)[0];
interface IContext {
  selectedScenario: ICashflowScenario;
  allScenarios: ICashflowScenario[];
  fetchCashflowReport: () => void;
  fetchScenarios: () => void;
  syncingInfo: ISyncingLogs;
  currentPlan: PlanType;
  totalSyncs: number;
  checkingSyncingStatus: boolean;
  [key: string]: any;
}

export const myContext = createContext<IContext>({} as IContext);

export function useAuth() {
  return useContext(myContext);
}

export default function Context(props: PropsWithChildren<any>) {
  const [user, setUser] = useState<any>();
  const [cashflowStage, setCashflowStage] = useState<any>(0);
  const [connectedToXero, setConnectedToXero] = useState<boolean>();
  const [connectedToQuickBooks, setConnectedToQuickBooks] = useState<boolean>();
  const [connectedToZoho, setConnectedToZoho] = useState<boolean>();
  const [xeroOrganizationName, setXeroOrganizationName] = useState<string>("");
  const [qboOrganizationName, setQboOrganizationName] = useState<string>("");
  const [zohoOrganizationName, setZohoOrganizationName] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [bankConnected, setBankConnected] = useState<boolean>(false);
  const [financialUrl, setFinancialUrl] = useState<string>("");
  const [currenciesExchangeDate, setCurrenciesExchangeDate] =
    useState<string>();
  const [baseCurrency, setBaseCurrency] = useState<string>("AED");
  const [organization, setOrganization] = useState<any>({});

  const [globalLoading, setGlobalLoading] = useState(false);
  const [serverCrashed, setServerCrashed] = useState(false);
  const [customerId, setCustomerId] = useState<string>("");
  const [cashflowBuckets, setCashflowBuckets] = useState<any>([]);
  const [syncingInfo, setSyncingInfo] = useState<ISyncingLogs>(
    {} as ISyncingLogs
  );
  const [isSyncingAllowed, setIsSyncingAllowed] = useState(false);
  const [checkingSyncingStatus, setCheckingSyncingStatus] = useState(true);
  const [totalSyncs, setTotalSyncs] = useState(0);

  const [currentPlan, setCurrentPlan] = useState({} as PlanType);

  const financialProjection = useFinancialProjection();

  const [demoDataExists, setDemoDataExists] = useState(false);

  const [trialDaysRemaining, setTrialDaysRemaining] = useState(0);

  const [errorInInvite, setErrorInInvite] = useState(false);

  const [allContacts, setAllContacts] = useState<any[]>([]);
  const [contactLoader, setContactLoader] = useState<boolean>(false);

  const {
    reportRows,
    setReportRows,
    fetchCashflowReport,
    cashflowConfigs,
    setCashflowConfigs,
    reportLoading,
    setReportLoading,
    configsLoading,
    obErrorMessage,
    setObErrorMessage,
    obMessage,
    setObMessage,
    fetchCashflowConfigs,
    selectedScenario,
    allScenarios,
    fetchScenarios,
    dashboardData,
    getDashboardData,
    dashboardLoading,
    compareTo,
    setCompareTo,
  } = useCashflow();
  const {
    records,
    setRecords,
    getRecords,
    externalAccounts,
    fetchExternalAccounts,
    setExternalAccounts,
    recordsReceivable,
    recordsLoading,
    totalRecords,
    contactsLoading,
  } = useRecords({ isGlobalContext: true });

  const isUserPartOfOrganization = async (
    tenantId: string,
    provider: string
  ) => {
    axios.defaults.withCredentials = true;
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/isUserPartOfOrganization",
      {
        tenantId,
        provider,
      }
    );

    if (!data || data === "error") {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    (async () => {
      const { success } = await doesDemoDataExists();
      setDemoDataExists(success);
    })();
  });

  const checkXeroConnection = axios.get(
    configs.urls.BASE_URL + "/getAccessToken",
    {
      withCredentials: true,
    }
  );

  const checkQboConnection = axios.get(
    configs.urls.BASE_URL + "/checkConnectionToQuickBooks",
    {
      withCredentials: true,
    }
  );

  const checkZohoConnection = axios.get(
    configs.urls.BASE_URL + "/zoho/checkConnection",
    {
      withCredentials: true,
    }
  );
  const isBankConnected = axios.get(
    configs.urls.BASE_URL + "/isBankConnected",
    {
      withCredentials: true,
    }
  );

  let provider = useCallback(() => {
    if (connectedToXero) {
      return "Xero";
    } else if (connectedToQuickBooks) {
      return "QuickBooks";
    } else if (connectedToZoho) {
      return "Zoho";
    } else return "";
  }, [connectedToXero, connectedToQuickBooks, connectedToZoho]);

  const fetchConnections = async () => {
    const values = await Promise.all([
      // getUser,
      checkXeroConnection,
      checkQboConnection,
      // getCurrentOrganization,
      isBankConnected,
      checkZohoConnection,
    ]);

    // // get user
    // const userResponse = values[0];
    // setUser(userResponse.data);

    // // get organization
    // const orgResponse = values[3];
    // if(orgResponse.data !== "error") {
    //   setOrganizationName(orgResponse.data.organization.name);
    //   setBaseCurrency(orgResponse.data.baseCurrency);
    //   setFinancialUrl(orgResponse.data.financialUrl);
    //   setOrganization(orgResponse.data.organization);
    // }

    // get xero connection
    const xeroResponse = values[0];
    if (xeroResponse.data === "success") {
      setConnectedToXero(true);
      getLastSyncTime("Xero");
      //getOrganization
      axios
        .get(configs.urls.BASE_URL + "/organisation", {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data !== "error") {
            setXeroOrganizationName(res.data);
          }
        });
    } else {
      setConnectedToXero(false);
      // get qbo connection
    }

    const qboResponse = values[1];
    if (qboResponse.data === "success") {
      setConnectedToQuickBooks(true);
      getLastSyncTime("QuickBooks");
      //getOrganization
      axios
        .get(configs.urls.BASE_URL + "/getQboOrganization", {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data !== "error") {
            setQboOrganizationName(res.data);
          }
        });
    } else {
      setConnectedToQuickBooks(false);
      // get zoho connection
    }

    const zohoResponse = values[3];
    if (zohoResponse.data === "success") {
      setConnectedToZoho(true);
      getLastSyncTime("Zoho");
      //getOrganization
      axios
        .get(configs.urls.BASE_URL + "/zoho/organization", {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data !== "error") {
            setZohoOrganizationName(res.data.organizationName);
          }
        });
    } else {
      setConnectedToZoho(false);
    }

    // get bank connection
    const bankResponse = values[2];
    if (bankResponse.data.success) {
      setBankConnected(true);
      getLastBankSyncDate();
    } else {
      setBankConnected(false);
    }

    setLoading(false);
  };

  const getReportLayout = () => {
    //getReportLayout
    axios.defaults.withCredentials = true;
    axios.get(configs.urls.BASE_URL + "/getReportLayout").then((res) => {
      if (res.data !== "error") {
        setCashflowBuckets(res.data);
      }
    });
  };

  const fetchCustomerId = () => {
    axios
      .get(configs.urls.BASE_URL + "/getLeanCustomerId", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data !== "error") {
          setCustomerId(res.data);
        }
      });
  };

  const getSyncingLogs = async () => {
    const { data, success } = await getSyncingInfo();
    if (success) {
      setSyncingInfo(data);
    }
    setCheckingSyncingStatus(false);
  };

  const getAllContacts = async () => {
    try {
      setContactLoader(true);
      const response = await axios.get(
        configs.urls.BASE_URL + "/contact/getAll",
        {
          withCredentials: true,
        }
      );

      if (response.data.success) {
        setAllContacts(response.data.response.contacts);
      }
    } catch (err) {
      console.log("Error getAllContacts: ", err);
    } finally {
      setContactLoader(false);
    }
  };

  useEffect(() => {
    if (_.isEmpty(syncingInfo) || _.isEmpty(organization)) return;

    let totalSyncs = syncingInfo.totalSuccessfulSyncs;

    let isSyncingAllowed = doPlanAllowMoreSyncs(
      organization?.subscription,
      totalSyncs
    );

    setIsSyncingAllowed(isSyncingAllowed);
    setTotalSyncs(totalSyncs);

    let currentPlan =
      PLANS.find((plan) => plan.tier === organization?.subscription?.tier) ||
      ({} as PlanType);

    setCurrentPlan(currentPlan);
  }, [syncingInfo, organization]);

  const fetchUser = async () => {
    const userResponse = await axios.get(configs.urls.BASE_URL + "/user", {
      withCredentials: true,
    });
    setUser(userResponse.data);
    try {
      if (
        userResponse.data
        // &&
        // userResponse.data.lastStage === "you-are-all-set-up"
      ) {
        getDashboardData();
        await getRecords();
        await fetchOrganization();
        await fetchConnections();
        fetchCustomerId();
        await fetchExternalAccounts();
        await fetchCashflowConfigs();
        await fetchCashflowReport();

        await getSyncingLogs();

        getReportLayout();

        getAllContacts();
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const fetchOrganization = async () => {
    const orgResponse = await axios.get(
      configs.urls.BASE_URL + "/getOrganization",
      {
        withCredentials: true,
      }
    );
    if (orgResponse.data !== "error") {
      setOrganizationName(orgResponse.data.organization.name);
      setBaseCurrency(orgResponse.data.baseCurrency);
      setFinancialUrl(orgResponse.data.financialUrl);
      setOrganization(orgResponse.data.organization);
      setCurrenciesExchangeDate(orgResponse.data.currenciesExchangeDate);
    }
  };

  // check if server was crashed
  const ifServerCrashed = async () => {
    try {
      const response = await axios.get(configs.urls.BASE_URL + "/health");
      console.log("Server status: ", response.data);
      fetchUser();
      setServerCrashed(false);
    } catch (error) {
      console.log("/api/health error: ", error);
      setServerCrashed(true);
    }
  };

  useEffect(() => {
    const currentUrl = new URL(window.location.href);

    if (currentUrl.hostname === "127.0.0.1") {
      currentUrl.hostname = "localhost";
      window.location.href = currentUrl.href;
    }

    ifServerCrashed();
    // fetchCashflowConfigs();
  }, []);

  /// ---------- Syncing things with server ---------------- ///
  const [status, setStatus] = useState(STATUS);
  const [syncing, setSyncingState] = useState(false);
  const [syncingError, setSyncingError] = useState("");
  const [lastSyncDate, setLastSyncDate] = useState<Date>();
  const [lastBankSyncDate, setLastBankSyncDate] = useState<Date>();

  const setSyncing = () => {
    setStatus(STATUS);

    setSyncingState(true);
  };

  const getLastSyncTime = async (provider: string) => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axios.defaults.withCredentials = true;
    axios
      .post(
        configs.urls.BASE_URL + "/getLastSyncDate",
        {
          provider,
        },
        options
      )
      .then((res) => {
        if (res.data && res.data !== "error") {
          setLastSyncDate(new Date(res.data));
        }
      });
  };

  const getLastBankSyncDate = async () => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axios.defaults.withCredentials = true;
    axios
      .get(configs.urls.BASE_URL + "/getLastBankSyncDate", options)
      .then((res) => {
        if (res.data && res.data !== "error") {
          setLastBankSyncDate(new Date(res.data));
        }
      });
  };

  useEffect(() => {
    sync_server.on("data_syncing", async (data: any) => {
      const orgResponse = await axios.get(
        configs.urls.BASE_URL + "/getOrganization",
        {
          withCredentials: true,
        }
      );
      let orgId = orgResponse.data.organization.id;

      if (data.organizationId === orgId) {
        setStatus((prev: any) => {
          const newData = { ...prev, ...data?.status };

          if (
            Object.values(newData).every((val) => val) ||
            newData?.timeSpent
          ) {
            setSyncingState(false);

            getLastSyncTime(provider());
            // getRecords();
            fetchExternalAccounts();
            fetchCashflowReport();
            getSyncingLogs();
          } else {
            setSyncingState(true);
          }

          return newData;
        });

        if (data?.error) {
          setSyncingError(data?.error.message);
          setSyncingState(false);
        } else {
          setSyncingError("");
        }

        if (data?.status?.start && !data?.status?.timeSpent) {
          setSyncingState(true);
        }
      }
    });
    sync_server.on("connect_error", function (err) {
      setSyncingError(
        "Sorry, there seems to be an issue with the connection to our server! (" +
          err?.message +
          ")"
      );
      setSyncingState(false);
    });
    sync_server.on("connect", function () {
      setSyncingError("");
      setSyncingState(false);
    });
  }, [sync_server]);

  useEffect(() => {
    core_server.on("connect_error", function () {
      setServerCrashed(true);
    });
    core_server.on("connect", function () {
      setServerCrashed(false);
    });
  }, [core_server]);

  return (
    <myContext.Provider
      value={{
        user,
        cashflowStage,
        setCashflowStage,
        connectedToXero,
        xeroOrganizationName,
        connectedToQuickBooks,
        globalLoading,
        qboOrganizationName,
        connectedToZoho,
        zohoOrganizationName,
        setGlobalLoading,
        isUserPartOfOrganization,
        organizationName,
        bankConnected,
        setBankConnected,
        fetchConnections,
        customerId,
        financialUrl,
        baseCurrency,
        setBaseCurrency,
        organization,
        fetchUser,
        fetchOrganization,
        reportRows,
        setReportRows,
        fetchCashflowReport,
        cashflowConfigs,
        setCashflowConfigs,
        reportLoading,
        setReportLoading,
        configsLoading,
        obErrorMessage,
        setObErrorMessage,
        obMessage,
        setObMessage,
        records,
        setRecords,
        getRecords,
        externalAccounts,
        fetchExternalAccounts,
        setExternalAccounts,
        recordsReceivable,
        recordsLoading,
        totalRecords,
        contactsLoading,
        currenciesExchangeDate,
        cashflowBuckets,
        setCashflowBuckets,
        getReportLayout,
        status,
        setStatus,
        syncing,
        setSyncing,
        syncingError,
        lastSyncDate,
        lastBankSyncDate,
        ...financialProjection,
        selectedScenario,
        allScenarios,
        fetchScenarios,
        fetchCashflowConfigs,
        syncingInfo,
        isSyncingAllowed,
        currentPlan,
        totalSyncs,
        checkingSyncingStatus,
        demoDataExists,
        dashboardData,
        dashboardLoading,
        compareTo,
        setCompareTo,
        trialDaysRemaining,
        setTrialDaysRemaining,
        errorInInvite,
        setErrorInInvite,
        contactLoader,
        setContactLoader,
        getAllContacts,
        allContacts,
        setAllContacts
      }}
    >
      {serverCrashed ? (
        <div className="is-flex hero is-flex-direction-row is-justify-content-center is-align-items-center is-fullheight">
          <img
            style={{
              width: "40%",
              height: "40%",
            }}
            src={require("../assets/images/oops.png")}
            alt="Server crashed..."
          />
        </div>
      ) : !loading ? (
        props.children
      ) : (
        user && <Loader />
      )}
    </myContext.Provider>
  );
}
